module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"26VNiqp34uLIFUzWxq1aHCMOlAr","devKey":"26VNiqp34uLIFUzWxq1aHCMOlAr","dataPlaneUrl":"https://devfoliouaji.dataplane.rudderstack.com","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devfolio - Your one application to the best hackathons","short_name":"Devfolio - Your one application to the best hackathons","start_url":"/","background_color":"#f2f2f3","theme_color":"#3770ff","display":"minimal-ui","icon":"static/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"da277412126715174c95f7a745de87a3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
