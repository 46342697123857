// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-backend-2019-js": () => import("./../../../src/pages/backend/2019.js" /* webpackChunkName: "component---src-pages-backend-2019-js" */),
  "component---src-pages-backend-index-js": () => import("./../../../src/pages/backend/index.js" /* webpackChunkName: "component---src-pages-backend-index-js" */),
  "component---src-pages-frontend-2019-js": () => import("./../../../src/pages/frontend/2019.js" /* webpackChunkName: "component---src-pages-frontend-2019-js" */),
  "component---src-pages-frontend-index-js": () => import("./../../../src/pages/frontend/index.js" /* webpackChunkName: "component---src-pages-frontend-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

